<template>
  <div>
    <b-card title="Admin? 🔒">
      <b-card-text>Weefforts.</b-card-text>
      <b-card-text>Please reaation.</b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },

  data() {
    return {}
  },
}
</script>

<style>

</style>
